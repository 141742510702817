import React, {useEffect, useState} from 'react'
import {Card, CardActions, CardContent, Paper, Typography} from "@material-ui/core";
import {CompanyDto, findCompanyById, deleteCompanyById} from "../../api/CompanyApi";
import {navigate} from "gatsby";

import ConfirmDialog from "../common/ConfirmDialog";
import Header from "../common/Header";
import ButtonStyled from "../common/ButtonStyled";

import { useStyles } from "../../styles/CommonStyles";
import { useTranslation } from 'react-i18next';
import BackendError from "../common/BackendError";

interface CompanyDetailProps{
    id: number,
    style?: any,
}
export default function CompanyDetail( props: CompanyDetailProps){
    const classes = useStyles();
    const { t } = useTranslation();

    const [company, setCompany] = useState<CompanyDto>(null)
    const [backendErrorMessage, setBackendErrorMessage] = useState(null)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)

    const deleteCompany = () => {
        deleteCompanyById(props.id).then((status) => {
            navigate(-1)
        }).catch(err=> {
            (err.response!=null && err.response.data.message!=null) ? setBackendErrorMessage(err.response.data.message) : setBackendErrorMessage(t("ERRDELETECOMPANY"))
            setDeleteDialogOpen(false)
        })
    }

    useEffect(() => {
        findCompanyById(props.id).then(data =>{
            setCompany(data)
        }).catch(err => {
            console.log("Error getting company with id " + props.id, err)
            navigate("/404")
        })
    },[])

    return (
        <Paper className={classes.root} style={props.style}>
            {company!=null ?
            <Card variant="outlined">
                <Header title={t("COMPANYDETAIL")} titleTypographyProps={{variant:"h5", align:"center"}}/>
                <CardContent>
                    {backendErrorMessage!=null && <BackendError error={backendErrorMessage}/>}
                    <Typography className={classes.fieldTitle} variant="h6">
                        {t("NAME")}
                    </Typography>
                    <Typography variant="body1" className={classes.fieldContent}>
                        {company.name}
                    </Typography>
                    <Typography className={classes.fieldTitle} variant="h6">
                        {t("LOGO")}
                    </Typography>
                    <Typography variant="body1" className={classes.fieldContent}>
                        TODO
                    </Typography>
                </CardContent>
                <CardActions className={classes.cardActions}>
                    <ButtonStyled
                        text={t("DELETE")}
                        onClick={() => setDeleteDialogOpen(true)}/>
                    <ConfirmDialog
                        title={t("DELETECOMPANY")}
                        open={deleteDialogOpen}
                        onClose={()=>setDeleteDialogOpen(false)}
                        onConfirm={deleteCompany}
                    >
                        {t("DELETECOMPANYCONFIRM",{companyName: company.name})}
                    </ConfirmDialog>
                    <ButtonStyled
                        text={t("EDIT")}
                        onClick={() => navigate("/companies/"+props.id+"/edit")}/>
                </CardActions>
            </Card>
            : <div/>}
        </Paper>
    )
}