import React from 'react';
import Layout from '../../../components/Layout';

import CompanyDetail from "../../../components/companies/CompanyDetail";

export default function CompanyDetailPage( props ) {

    return (
        <Layout allowAnonymous={false}>
            <CompanyDetail style={{minWidth:700, width:"50%", marginLeft: "auto", marginRight: "auto"}} id={props.params.companyId}/>
        </Layout>
    )
}